
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CostCenterCodeMappingEntity, EMPTY_COST_CENTER_CODE_MAPPING_ENTITY } from "domain/entity/CostCenterCodeMapping/CostCenterCodeMappingEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CostCenterCodeMappingRepository } from "domain/repository/CostCenterCodeMapping/CostCenterCodeMappingRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { createCostCenterCodeMappingMaintenanceValidationSchema } from "presentation/constant/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { CostCenterCodeMappingMaintenanceModel } from "presentation/model/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface CostCenterCodeMappingMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CostCenterCodeMappingMaintenanceModel>> | ((value: SetStateAction<CostCenterCodeMappingMaintenanceModel>) => void),
    ],
    repo: CostCenterCodeMappingRepository,
    companyRepo: CompanyRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    chargeTypeRepo: ChargeTypeRepository,
    tariffTypeRepo: TariffTypeRepository,
}

export const CostCenterCodeMappingMaintenanceVM = ({ dispatch, repo, companyRepo, standardTariffCodeRepo, chargeTypeRepo, tariffTypeRepo }: CostCenterCodeMappingMaintenanceVMProps) => {
    const [costCenterCodeMappingMainDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypeList = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let newChargeTypes = newChargeTypeList?.filter(entity => entity.chargeCategory === 'GEN' || entity.chargeCategory === 'SYS');
                let chargeTypeDropdownOption: DropdownProps[] = [];
                let subChargeTypeDropdownOption: { [key: string]: DropdownProps[] } = {};
                newChargeTypes?.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                costCenterCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                costCenterCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);
                let tariffTypeDropdownOption = newTariffs?.map((tariff) => ({
                    dropdownLabel: tariff.tariffType,
                    tagLabel: tariff.tariffType,
                    value: tariff.tariffType,
                })) ?? []
                costCenterCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOption,
                    }
                }))
            }
        );

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                // let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                // let allTariffCodeeDropdownOptions: DropdownProps[] = [];

                newTariffs?.forEach(tariff => {
                    // const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffType);
                    // if (!isTariffTypeExisted) {
                    //     tariffTypeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffType,
                    //         tagLabel: tariff.tariffType,
                    //         value: tariff.tariffType,
                    //     })
                    // }

                    // const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffCode);

                    // if (!isTariffCodeExisted) {
                    //     allTariffCodeeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffCode,
                    //         tagLabel: tariff.tariffCode,
                    //         value: tariff.tariffCode,
                    //     })
                    // }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                costCenterCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        // tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const onSearch = async () => {
        /*costCenterCodeMappingMainDispatch(prevState => ({
            ...prevState,
            isShowDtl: false,
            tableData: [],
            selectedRows: [],
            currentSelectedRow: EMPTY_COST_CENTER_CODE_MAPPING_ENTITY,
        }))*/
        await repo.getEntities().then((entities) => {
            costCenterCodeMappingMainDispatch(prevState => ({
                ...prevState,
                tableData: _.sortBy(entities, ["priority"]),
                selectedRows: [],
                currentSelectedRow: EMPTY_COST_CENTER_CODE_MAPPING_ENTITY,
                isShowDtl: false,
            }))

        })

    }

    const updateSelectedRows = async (selecedRows: CostCenterCodeMappingEntity[]) => {
        costCenterCodeMappingMainDispatch(prevState => {

            return {
                ...prevState,
                selectedRows: selecedRows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onAdd = () => {
        costCenterCodeMappingMainDispatch(prevState => {

            return {
                ...prevState,
                isShowDtl: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_COST_CENTER_CODE_MAPPING_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: CostCenterCodeMappingEntity) => {
        costCenterCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowDtl: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = () => {
        costCenterCodeMappingMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_COST_CENTER_CODE_MAPPING_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        costCenterCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDtl: false,
                currentSelectedRow: EMPTY_COST_CENTER_CODE_MAPPING_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_COST_CENTER_CODE_MAPPING_ENTITY,
                    allFormState: {}
                },
                isBackMaster: true,
            }
        })
    }

    const onSaveClicked = () => {
        costCenterCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: CostCenterCodeMappingEntity, isAdd: boolean) => {
        const valResult = await Validation(createCostCenterCodeMappingMaintenanceValidationSchema).ValidateFormOnly(currentEntity);
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            costCenterCodeMappingMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });

            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }

        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        costCenterCodeMappingMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if (fieldKey === 'costCenterCode') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        costCenterCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onApply = async (updatedRows: CostCenterCodeMappingEntity[]) => {

        return await repo.onApply(updatedRows);
    }

    const onEditClicked = () => {
        costCenterCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isEditable: true,
                }
            }
        })
    }



    return {
        onEditClicked: onEditClicked,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onApply: onApply,
    }
}